import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { User } from '../class/user';
import { Productline } from '../class/productline';
import { ProductModel } from '../class/product-model';
import { Invoice } from '../class/invoice';
import { Customer } from '../pages/tables/all-in-one-table/customer-create-update/customer.model';
import { Crosscode } from '../class/crosscode';
import { InvoiceMeta } from '../class/invoice-meta';
import { InvoiceItem } from '../class/invoice-item';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://www.nycofamerica.com/develop/?html=t&'; //develop rest


@Injectable({
  providedIn: 'root'
})
export class HttpService {




  constructor(public http: HttpClient, private storage: LocalStorage,private router: Router) { }

  getValidate(user: User): Observable<User> {
    return this.http.post<User>(apiUrl + '&op=login', user, httpOptions).pipe(
      tap((u: User) => console.log(`validate user w/ id=${u.iduser}`)),
      catchError(this.handleError<User>('getValidate'))
    );
  }

  getProducts(user: User, idproduct_line: number): Observable<any> {
    const p = (idproduct_line > 0) ? ('&parent=' + idproduct_line) : '';
    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<ProductModel[]>(apiUrl + 'op=get-products&' + t + p, httpOptions).pipe(
      tap(() => console.log(`validate productoline w/`)),
      catchError(this.handleSession<ProductModel>('get-product-line'))
    );

  }

  getTraking(u: User, id: number): Observable<any> {


    const p = '&idinvoice=' + id;

    return this.http.get<ProductModel[]>(apiUrl + 'op=get-traking&token=' + u.token + p+ "&t=" + Math.random(), httpOptions).pipe(
      tap(() => console.log(`getTraking w/`)),
      catchError(this.handleError<ProductModel>('getTraking'))
    );

  }

  setUserData(u: User, name: string, email: string, password: string): Observable<any> {


    const data = { 'name': name, 'email': email,  'pass': password  };
    return this.http.post<String[]>(apiUrl + '&op=set-userdata&token=' + u.token, data, httpOptions).pipe(
      tap(() => console.log(`setUserData w/`)),
      catchError(this.handleError<User>('setUserData'))
    );

  }

  setDataAddress(u: User, customer: any,  address: string, city: string, zipcode: string, address2: string, phone: string): Observable<any> {


    const data = { 'address': address, 'city': city,  'zipcode': zipcode,  'address2': address2 ,  'phone': phone   };
    return this.http.post<String[]>(apiUrl + '&op=set-data-customer&token=' + u.token+"&idcustomer="+customer.idcustomer, data, httpOptions).pipe(
      tap(() => console.log(`setUserData w/`)),
      catchError(this.handleError<User>('setUserData'))
    );
 
  }

  setInvite(u: User, customer: any,  pr_invname: string,  pr_invposition: string,  pr_invemail: string,  pr_invpass: string): Observable<any> {


    const data = { 'name': pr_invname, 'position': pr_invposition,  'email': pr_invemail,  'pass': pr_invpass   };
    return this.http.post<String[]>(apiUrl + '&op=add-user-customer&token=' + u.token+"&idcustomer="+customer.idcustomer, data, httpOptions).pipe(
      tap(() => console.log(`setInvite w/`)),
      catchError(this.handleOnly<any>('setInvite'))
    );
 
  }

  getCustomerTeam(u: User, customer: any): Observable<any> {


    const p = '&idcustomer=' + customer.idcustomer;
    const t = (u.token !== undefined) ? ('&token=' + u.token) : '';
    return this.http.get<ProductModel[]>(apiUrl + 'op=get-user-team&'+ t + p, httpOptions).pipe(
      tap(() => console.log(`get-user-team w/`)),
      catchError(this.handleOnly<ProductModel>('get-user-team'))
    );

  }

  setPrivileges(u: User, customer: any, row: any): Observable<any> {


    const p = '&idteam=' + row.idcustomer_team+"&c="+row.privileges;
    const t = (u.token !== undefined) ? ('&token=' + u.token) : '';
    return this.http.get<ProductModel[]>(apiUrl + 'op=set-privileges&'+ t + p, httpOptions).pipe(
      tap(() => console.log(`setPrivileges w/`)),
      catchError(this.handleError<ProductModel>('setPrivileges'))
    );

  }

  getProductsByTxt(value: any, user: User): Observable<any> {


    const p = '&txt=' + value;
    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<ProductModel[]>(apiUrl + 'op=get-productsByTxt&'+ t + p, httpOptions).pipe(
      tap(() => console.log(`get-product-Bytxt w/`)),
      catchError(this.handleSession<ProductModel>('get-product-Bytxt'))
    );

  }

  getProductsByTxtLimit(value: any, user: User): Observable<any> {

    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    const p = '&txt=' + value+'&limit=100';

    return this.http.get<ProductModel[]>(apiUrl + 'op=get-productsByTxt&'+ t + p, httpOptions).pipe(
      tap(() => console.log(`get-product-Bytxt w/`)),
      catchError(this.handleSession<ProductModel>('get-product-Bytxt'))
    );

  }

  getProductBySku(user: User, sku: string): Observable<any> {

    const p = '&sku=' + sku;
    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<ProductModel[]>(apiUrl + 'op=get-product&' + t + p, httpOptions).pipe(
      tap(() => console.log(`validate product by sku w/`)),
      catchError(this.handleSession<ProductModel>('validate product by sku'))
    );

  }

  getPrepaid(user: User): Observable<any> {

    const p = '&p=1';
    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<ProductModel[]>(apiUrl + 'op=get-prepaid&' + t + p, httpOptions).pipe(
      tap(() => console.log(`Prepaid w/`)),
      catchError(this.handleOnly<User>('getPrepaid'))
    );

  }

  getProductByLots(user: User, meta: any[]): Observable<any> {

    const data = { 'sku': meta };
    return this.http.post<String[]>(apiUrl + '&op=get-product-lots&token=' + user.token, data, httpOptions).pipe(
      tap(() => console.log(`getProductByLots w/`)),
      catchError(this.handleOnly<User>('getProductByLots'))
    );

  }


  updateOrder(user: User, meta: any[]): Observable<any> {

    const data = { 'sku': meta };
    return this.http.post<String[]>(apiUrl + '&op=get-product-lots&token=' + user.token, data, httpOptions).pipe(
      tap(() => console.log(`getProductByLots w/`)),
      catchError(this.handleError<User>('getProductByLots'))
    );

  }



  getTotalInvoice(user: User, idcustomer: String): Observable<any> {

    const t = (user.token !== undefined) ? ('&token=' + user.token)+"&idcustomer="+idcustomer : '';
    return this.http.get<ProductModel[]>(apiUrl + 'op=get-total-invoice&' + t, httpOptions).pipe(
      tap(() => console.log(`get total invoice`)),
      catchError(this.handleNothing<ProductModel>('get-total-invoice'))
    );

  }

  getInvoice(user: User, page: number, idcustomer: String, ordernumber: string, invoicenumber: string, customer: string, stardate: string, enddate: string): Observable<any> {

    const p = (page > 0) ? ('&page=' + page) : '';

    const t = (user.token !== undefined) ? ("&t=" + Math.random()+'&token=' + user.token +"&idcustomer="+idcustomer+"&ordernumber="+ordernumber+"&invoicenumber="+invoicenumber+"&customer="+customer+"&stardate="+stardate+"&endate="+enddate) : '';
    return this.http.get<any>(apiUrl + 'op=get-invoices&' + p + t + "&t=" + Math.random(), httpOptions).pipe(
      tap(() => console.log(`get invoice`)),
      catchError(this.handleNothing<ProductModel>('get-invoice'))
    );

  }

  // Cart

  getCart(user:User, customer: any): Observable<any> {
     
    const t = (user.token !== undefined) ? ('&token=' + user.token+'&idcustomer='+customer) : '';
    return this.http.get<Productline[]>(apiUrl + 'op=get-cart'+t, httpOptions).pipe(
      tap(() => console.log(`getCart w/`)),
      catchError(this.handleError<Productline>('getCart'))
    );
  }

  setCart(user:User, customer: any, cart: any): Observable<any> {
    const meta = {idcustomer: customer, cart};
    console.log(meta);
    return this.http.post<string[]>(apiUrl + '&op=set-cart&token='+user.token+ "&t=" + Math.random(), meta, httpOptions).pipe(
      tap(() => console.log(`setCart w/`)),
      catchError(this.handleNothing<User>('setCart'))
    );
  }

  // Product line

  getProductLine(user: User, parent: number): Observable<any> {
    const p = (parent > 0) ? ('&parent=' + parent) : '';
    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<Productline[]>(apiUrl + 'op=get-product-line&withChild=t' + t + p, httpOptions).pipe(
      tap(() => console.log(`validate productoline w/`)),
      catchError(this.handleError<Productline>('get-product-line'))
    );
  }


  getProductCrossCode(user: User, idproduct: string): Observable<any> {
    const p = '&idproduct=' + idproduct;
    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<Crosscode[]>(apiUrl + 'op=get-products-crosscode' + t + p, httpOptions).pipe(
      tap(() => console.log(`product cross w/`)),
      catchError(this.handleError<Productline>('getProductCrossCode'))
    );
  }

  setInvoiceMeta(user: User, idinvoice: number, meta: InvoiceMeta): Observable<any> {
    console.log(meta);
    return this.http.post<string[]>(apiUrl + '&op=set-invoice-meta&token='+user.token+'&idinvoice=' + idinvoice+ "&t=" + Math.random(), meta, httpOptions).pipe(
      tap(() => console.log(`setInvoiceMeta w/`)),
      catchError(this.handleError<User>('setInvoiceMeta'))
    );
  }


  getInvoiceMeta(user: User,  idinvoice: number): Observable<InvoiceMeta> {
    
    return this.http.get<InvoiceMeta>(apiUrl + 'op=get-invoice-meta&token='+user.token+"&idinvoice="+ idinvoice + "&t=" + Math.random() , httpOptions).pipe(
      tap(() => console.log(`getInvoiceMeta w/`)),
      catchError(this.handleError<InvoiceMeta>('getInvoiceMeta'))
    );
  }

  getInvoiceItem(user: User,  idinvoice: number): Observable<any> {
    
    return this.http.get<InvoiceItem[]>(apiUrl + 'op=get-invoice-item-meta&token='+user.token+"&idinvoice="+ idinvoice + "&t=" + Math.random() , httpOptions).pipe(
      tap(() => console.log(`getInvoiceMeta w/`)),
      catchError(this.handleError<any>('getInvoiceMeta'))
    );
  }

  logout(user: User ): Observable<any> {
    
    return this.http.get<InvoiceItem[]>(apiUrl + 'op=out&token='+user.token   + "&t=" + Math.random() , httpOptions).pipe(
      tap(() => console.log(`logout w/`)),
      catchError(this.handleError<any>('logout'))
    );
  }

  

  setTraking(user: User, txt: string, idinvoice: number): Observable<any> {
    const data = { 'obs': txt, idinvoice };
    return this.http.post<String[]>(apiUrl + '&op=set-traking&token=' + user.token+ "&t=" + Math.random(), data, httpOptions).pipe(
      tap(() => console.log(`getDataUser w/`)),
      catchError(this.handleError<User>('getDataUser'))
    );
  }

  

  setInvoiceItem(user: User, item: InvoiceItem): Observable<any> {
    const data = item;
    return this.http.post<String[]>(apiUrl + '&op=set-invoice-item-meta&token=' + user.token+ "&t=" + Math.random(), data, httpOptions).pipe(
      tap(() => console.log(`setInvoiceItem w/`)),
      catchError(this.handleError<User>('setInvoiceItem'))
    );
  }


  getDataUser(user: User,customer: any, meta: any[]): Observable<any> {
    const data = { 'meta': meta };
    return this.http.post<String[]>(apiUrl + '&op=get-data-user&token=' + user.token+"&idcustomer="+customer.idcustomer, data, httpOptions).pipe(
      tap(() => console.log(`getDataUser w/`)),
      catchError(this.handleError<User>('getDataUser'))
    );
  }

  getRecovery(email: string): Observable<any> {
    const data = { 'email': email };
    return this.http.post<any>('https://www.nycofamerica.com/recovery.php', data, httpOptions).pipe(
      tap(() => console.log(`getDataUser w/`)),
      catchError(this.handleError<User>('getDataUser'))
    );
  }


  // User

  setUser(user: User, u: any): Observable<any> {
    const data = { 'user': u };
    return this.http.post<String[]>(apiUrl + '&op=set-user&token=' + user.token, data, httpOptions).pipe(
      tap(() => console.log(`setUser w/`)),
      catchError(this.handleError<User>('setUser'))
    );
  }

  getUsers(user: User, type: string): Observable<any> {
    const p = (type !== '') ? ('&type=' + type) : '';
    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<Customer[]>(apiUrl + 'op=get-users&withChild=t' + t + p, httpOptions).pipe(
      tap(() => console.log(`get users w/`)),
      catchError(this.handleError<Productline>('getUsers'))
    );
  }

  delUser(user: User, u: any): Observable<any> {
    const data = { 'user': u };
    return this.http.post<String[]>(apiUrl + '&op=del-user&token=' + user.token, data, httpOptions).pipe(
      tap(() => console.log(`delUser w/`)),
      catchError(this.handleError<User>('delUser'))
    );
  }

  getSellers(user: User): Observable<any> {

    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<Customer[]>(apiUrl + 'op=get-sellers&withChild=t' + t, httpOptions).pipe(
      tap(() => console.log(`get users w/`)),
      catchError(this.handleError<Productline>('getUsers'))
    );
  }

  delUserTeam(user: User, id: string): Observable<any> {

    const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
    return this.http.get<any>(apiUrl + 'op=del-user-team&' + t + "&idteam="+id, httpOptions).pipe(
      tap(() => console.log(`deluserteam w/`)),
      catchError(this.handleError<Productline>('deluserteam'))
    );
  }

   

  addUserTeam(user: User, customer: any,name: string, position: string, email: string,  pass: string): Observable<any> {
    const data = { 'name': name, 'position':position,  'email':email, 'pass':pass  };
    return this.http.post<String[]>(apiUrl + '&op=add-user-customer&token=' + user.token+"&idcustomer="+customer.idcustomer, data, httpOptions).pipe(
      tap(() => console.log(`setUser w/`)),
      catchError(this.handleError<User>('setUser'))
    );
  }

  // Orders
  sendOrder(invoice: Invoice): Observable<any> {
    invoice.items = [];
    return this.http.post<Invoice>('/api/?token=' + invoice.user.token+"&_t=" +Math.random(), invoice, httpOptions).pipe(
      tap(() => console.log(`sendOrder w/`)),
      catchError(this.handleError<User>('sendOrder'))
    );
  }

  // Products
  getProduct(user: User, sku: String): Observable<any> {

    if (sku === '') {

    } else {
      const p = (sku !== '') ? ('&sku=' + sku) : '';
      const t = (user.token !== undefined) ? ('&token=' + user.token) : '';
      return this.http.get<Customer[]>(apiUrl + 'op=get-product&withChild=t' + t + p, httpOptions).pipe(
        tap(() => console.log(`get products w/`)),
        catchError(this.handleError<ProductModel>('getProducts'))
      );
    }
  }






  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      this.storage.clear().subscribe(() => {this.router.navigate(['/auth/login']); location.reload(); });


/*
      if(){

        location.reload();
      }*/
      
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  private handleSession<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      location.reload();
      //this.storage.clear().subscribe(() => {this.router.navigate(['/auth/login']); location.reload();});
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private handleNothing<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      location.reload();
      //this.storage.clear().subscribe(() => {this.router.navigate(['/auth/login']); location.reload();});
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private handleOnly<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
     
      //this.storage.clear().subscribe(() => {this.router.navigate(['/auth/login']); location.reload();});
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
